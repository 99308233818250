import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { defineStore, storeToRefs } from 'pinia'

import useNotificationsStore from '@/stores/notifications'
import useTranslationsStore from '@/stores/translations'
import useAuthStore from '@/stores/auth'
import useAuth from '@/composables/useAuth'
import { ROUTES } from '@/utilities/constants'
import env from '@/config/env'
import {
  WORKER_MESSAGES,
  STOMP_CLIENT_MESSAGES,
  type WorkerMessage
} from '@/types/stores/stompClient'

const useStompClient = defineStore('stomp-client', () => {
  const worker = new Worker(new URL('@/workers/stomp.ts', import.meta.url), {
    type: 'module'
  })

  const router = useRouter()
  const notificationsStore = useNotificationsStore()
  const translationsStore = useTranslationsStore()
  const authStore = useAuthStore()
  const auth = useAuth()

  const { qrCodeLinkData, authData, isCashoutLoading } = storeToRefs(authStore)

  const { addNotification } = notificationsStore
  const { t } = translationsStore

  const isClientActivated = ref(false)
  const currentSession = ref<string | null>(null)

  const sessionId = computed(() => authData.value?.sessionId || qrCodeLinkData.value?.sessionId)

  worker.onmessage = (e: MessageEvent<WorkerMessage>) => {
    const { type, payload } = e.data

    const messageHandler = {
      [WORKER_MESSAGES.CONNECTED]: () => {
        if (sessionId.value) {
          subscribeSession(sessionId.value)
        }
        isClientActivated.value = true
      },
      [WORKER_MESSAGES.MESSAGE]: async () => {
        switch (payload.type) {
          case STOMP_CLIENT_MESSAGES.SESSION_INITIATED:
            await router.push({
              name: ROUTES.OTP,
              params: {
                sessionId: sessionId.value
              }
            })
            break

          /**
           * When terminal is disabled/deleted from admin and user is logged in at that time,
           * SESSION_TERMINATED and TERMINAL_DEACTIVATED messages will be sent at the same time
           */
          case STOMP_CLIENT_MESSAGES.TERMINAL_DEACTIVATED:
            await router.push({ name: ROUTES.QR_CODE })
            break

          case STOMP_CLIENT_MESSAGES.SESSION_TERMINATED:
            if (isCashoutLoading.value) return

            auth.handlePostLogoutActions()

            await router.push({ name: ROUTES.QR_CODE })
            break

          case STOMP_CLIENT_MESSAGES.SUCCESSFUL_DEPOSIT:
            addNotification(t('successful_deposit_notification_message'), 'success')
            break
        }
      }
    }

    messageHandler[type as keyof typeof messageHandler]()
  }

  const subscribeSession = (sessionId: string) => {
    currentSession.value = sessionId

    worker.postMessage({
      type: WORKER_MESSAGES.SUBSCRIBE_SESSION,
      payload: { sessionId }
    })
  }

  const unsubscribeSession = (sessionId: string) => {
    worker.postMessage({
      type: WORKER_MESSAGES.UNSUBSCRIBE_SESSION,
      payload: { sessionId }
    })
  }

  const init = () => {
    worker.postMessage({
      type: WORKER_MESSAGES.INIT_CLIENT,
      payload: { brokerURL: env.ssbtStompUrl }
    })
  }

  return {
    currentSession,
    isClientActivated,
    init,
    subscribeSession,
    unsubscribeSession
  }
})

export default useStompClient
