import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { defineStore, storeToRefs } from 'pinia'
import { useTimeoutFn } from '@vueuse/core'
import GatewayMaster from '@nsftx/seven-gravity-gateway/master.js'

import useUserStore from '@/stores/user'
import useAuthStore from '@/stores/auth'
import useNotificationsStore from '@/stores/notifications'
import useTranslationsStore from '@/stores/translations'
import useModalStore from '@/stores/modal'
import useAuth from '@/composables/useAuth'
import { ROUTES } from '@/utilities/constants'
import {
  type SlaveEventMessage,
  GATEWAY_EVENT,
  GATEWAY_EMIT_EVENT,
  SLAVE_EVENT
} from '@/types/stores/gravityGateway'
import env from '@/config/env'

const USER_INACTIVE_TIMEOUT = 10 * 60 * 1000

const useGravityGatewayStore = defineStore('gravity-gateway', () => {
  const gateway = ref<any>(null)

  const router = useRouter()

  const userStore = useUserStore()
  const authStore = useAuthStore()
  const notificationsStore = useNotificationsStore()
  const translationsStore = useTranslationsStore()
  const modalStore = useModalStore()
  const auth = useAuth()
  const { start: startUserInactivityTimeout, stop: stopUserInactivityTimeout } = useTimeoutFn(
    () => {
      handleUserInactivity()
    },
    USER_INACTIVE_TIMEOUT,
    {
      immediate: false
    }
  )

  const { authData, isAuthenticated } = storeToRefs(authStore)
  const { userData, isCasinoGameOpen } = storeToRefs(userStore)
  const { modalState } = storeToRefs(modalStore)

  const { cashout } = authStore
  const { addNotification } = notificationsStore
  const { t } = translationsStore

  const initialSlaveUserData = computed(() => ({
    currency: {
      symbol: userData.value?.currency ?? env.tenant.currency,
      virtualSymbol: false
    },
    locale: {
      iso1: userData.value?.language ?? env.languageISO1,
      iso2: userData.value?.languageISO6392 ?? env.languageISO2
    },
    referrerUrl: window.encodeURIComponent(window.location.origin),
    settings: null,
    tenant: {
      name: env.tenant.name,
      uuid: env.tenant.uuid,
      country: env.tenant.country
    },
    user: {
      auth: {
        token: authData.value?.jwt
      },
      profile: {
        token: userData.value?.token,
        oddsDisplay: userData.value?.oddsDisplay,
        timezoneOffset: userData.value?.timezoneOffset
      },
      balance: userData.value?.balance,
      email: userData.value?.email,
      firstName: userData.value?.firstName,
      id: userData.value?.id,
      lastName: userData.value?.lastName,
      logged: isAuthenticated.value,
      name: userData.value?.username,
      nickname: userData.value?.nickname,
      uuid: userData.value?.uuid,
      country: userData.value?.country
    }
  }))

  const init = () => {
    const casinoAppURLOrigin = new URL(env.casinoAppUrl).origin
    const allowedOrigins = [casinoAppURLOrigin]
    if (env.isDevMode) {
      allowedOrigins.push('http://localhost:*')
    }

    gateway.value = GatewayMaster({
      slaves: {},
      allowedOrigins,
      debug: env.isDevMode
    })

    setListeners()
  }

  const notifyAuthorizationChanged = (auth: unknown) => {
    sendMessage(GATEWAY_EMIT_EVENT.UserAuthorizationChanged, { auth })
  }

  const notifyBalanceChanged = (balance: number) => {
    sendMessage(GATEWAY_EMIT_EVENT.UserBalanceChanged, { balance })
  }

  const sendMessage = (action: GATEWAY_EMIT_EVENT, data: unknown) => {
    gateway.value?.emit('PluginSevenCasino', {
      slaveId: 'PluginSevenCasino',
      frameId: 'PluginSevenCasino',
      action,
      data
    })
  }

  const removeSevenCasinoSlave = () => {
    stopUserInactivityTimeout()
    gateway.value.removeSlave('PluginSevenCasino')
  }

  const addSevenCasinoSlave = () => {
    gateway.value.addSlave({
      slaveId: 'PluginSevenCasino',
      frameId: 'PluginSevenCasino',
      autoResize: false,
      data: initialSlaveUserData.value,
      init: () => {},
      loaded: () => {
        if (isAuthenticated.value) {
          addNotification(
            `${t('general_welcome')} ${initialSlaveUserData.value.user.nickname}`,
            'success'
          )
        }

        startUserInactivityTimeout()
      }
    })
  }

  const setListeners = () => {
    gateway.value?.subscribe(GATEWAY_EVENT.SlaveEvent, (message: SlaveEventMessage) => {
      const eventHandler: Record<SLAVE_EVENT, () => void> = {
        [SLAVE_EVENT.GameOpen]: () => (isCasinoGameOpen.value = true),
        [SLAVE_EVENT.GameClose]: () => (isCasinoGameOpen.value = false),
        [SLAVE_EVENT.click]: () => {
          stopUserInactivityTimeout()
          startUserInactivityTimeout()
        }
      }

      eventHandler[message.event]?.()
    })

    gateway.value?.subscribe(GATEWAY_EVENT.AnalyticsSend, () => {})

    gateway.value?.subscribe(GATEWAY_EVENT.DocumentChangeMeta, () => {})

    gateway.value?.subscribe(GATEWAY_EVENT.DocumentChangeTitle, () => {})

    gateway.value?.subscribe(GATEWAY_EVENT.RouterRouteChanged, () => {})

    gateway.value?.subscribe(GATEWAY_EVENT.UserLoginRequired, () => {
      modalState.value.loginRequired = true
    })
  }

  // TODO: potentially add extra logic after all task details have been defined
  const goToQRCode = async () => {
    await router.push({ name: ROUTES.QR_CODE })
  }

  const logOut = async () => {
    await cashout({
      sessionId: authData.value?.sessionId ?? ''
    })

    auth.handlePostLogoutActions()
  }

  const handleUserInactivity = () => {
    isAuthenticated.value ? logOut() : goToQRCode()
  }

  return {
    initialSlaveUserData,
    init,
    notifyAuthorizationChanged,
    notifyBalanceChanged,
    addSevenCasinoSlave,
    removeSevenCasinoSlave
  }
})

export default useGravityGatewayStore
