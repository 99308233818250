import { createRouter, createWebHistory, START_LOCATION } from 'vue-router'

import useAuthStore from '@/stores/auth'
import { ROUTES } from '@/utilities/constants'
import env from '@/config/env'
import { storeToRefs } from 'pinia'

const router = createRouter({
  history: createWebHistory(env.baseUrl),
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/LoginLayout.vue'),
      children: [
        {
          path: '',
          name: ROUTES.QR_CODE,
          component: () => import('@/views/ScanQRCode.vue')
        },
        {
          path: '/otp/:sessionId',
          name: ROUTES.OTP,
          component: () => import('@/views/EnterOTP.vue')
        }
      ]
    },
    {
      path: '/lobby',
      name: ROUTES.LOBBY,
      component: () => import('@/layouts/LobbyLayout.vue')
    }
  ]
})

const setDongleIdInStore = (queryDongleId: unknown) => {
  const authStore = useAuthStore()
  const { dongleId } = storeToRefs(authStore)

  if (typeof queryDongleId === 'string') {
    dongleId.value = queryDongleId
  }
}

router.beforeEach((to, from, next) => {
  if (from === START_LOCATION) {
    setDongleIdInStore(to.query.dongleId)

    if (to.name !== ROUTES.QR_CODE) {
      return next({ name: ROUTES.QR_CODE })
    }
  }

  next()
})

export default router
