import '@/styles/main.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { SevenComponentsPlugin } from '@nsftx/seven-components'

import initErrorTracker from '@/utilities/errorTracker'
import env from './config/env'

import App from './App.vue'
import router from './router'

import '@nsftx/seven-components/palettes/yellow'
import '@nsftx/seven-components/style'
import '@nsftx/seven-components/styles/components/terminal'

const setupSevenComponentsStyle = () => {
  document.documentElement.setAttribute('data-sc-palette', 'yellow')
  document.documentElement.setAttribute('data-sc-theme', 'dark')
  document.documentElement.setAttribute('data-sc-channel', 'terminal')
}

;(async () => {
  const app = createApp(App)

  setupSevenComponentsStyle()
  if (!env.isDevMode) initErrorTracker(app)

  app.use(createPinia())
  app.use(router)
  app.use(SevenComponentsPlugin, {})

  app.mount('#app')
})()
