export enum WORKER_MESSAGES {
  INIT_CLIENT = 'INIT_CLIENT',
  SUBSCRIBE_SESSION = 'SUBSCRIBE_SESSION',
  UNSUBSCRIBE_SESSION = 'UNSUBSCRIBE_SESSION',
  CONNECTED = 'CONNECTED',
  MESSAGE = 'MESSAGE'
}

export enum STOMP_CLIENT_MESSAGES {
  SESSION_TERMINATED = 'SESSION_TERMINATED',
  SESSION_INITIATED = 'SESSION_INITIATED',
  TERMINAL_DEACTIVATED = 'TERMINAL_DEACTIVATED',
  SUCCESSFUL_DEPOSIT = 'SUCCESSFUL_DEPOSIT'
}

export type BaseSocketMessage = {
  sessionId: string
  timestamp: string
}

export type BaseSessionMessage = {
  type: WORKER_MESSAGES.MESSAGE
  payload: {
    type:
      | STOMP_CLIENT_MESSAGES.SESSION_TERMINATED
      | STOMP_CLIENT_MESSAGES.SESSION_INITIATED
      | STOMP_CLIENT_MESSAGES.TERMINAL_DEACTIVATED
    message: BaseSocketMessage
  }
}

export type SuccessfulDepositMessage = {
  type: WORKER_MESSAGES.MESSAGE
  payload: {
    type: STOMP_CLIENT_MESSAGES.SUCCESSFUL_DEPOSIT
    message: BaseSocketMessage & {
      amount: number
    }
  }
}

export type ConnectedMessage = {
  type: WORKER_MESSAGES.CONNECTED
  payload: never
}

export type WorkerMessage = BaseSessionMessage | SuccessfulDepositMessage | ConnectedMessage
